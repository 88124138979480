import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <p>〰 나는 나로 산다.<br/>
      💻 회사 밖 자립에 관심이 많은 IT 업계 프로덕트 디자이너<br/>
      🚗 운전 / 독서 / 힙한 카페 투어 / 애플 덕후</p>

      <a href="https://nanasan.notion.site/OKR-2ca1d3e3ab7f47bbae3c6caf834f8c4d?pvs=4" target="_blank">📑 OKR Notion 템플릿 by nanasan</a><br/>
      <p><a href="https://www.youtube.com/@nanasan-studio" target="_blank">📺 nanasan 유튜브 채널</a><br/>
      <a href="https://www.instagram.com/nanasan_studio/" target="_blank">🏠 nanasan 인스타그램</a></p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/5aU5WiKgl-E?si=mX1rlDGaEYEjqvgs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>    
    </Layout>
  )
}

export const Head = () => <Seo title="About" />

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
